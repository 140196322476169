import { AppState } from '@app/ngrx/app-state/app-state';
import { createSelector } from '@ngrx/store';

export const getAgentsState = (state: AppState) => state.agents;

export const getAgentsCollection = createSelector(getAgentsState, (state) => state.agents);

export const getAgentsLoading = createSelector(getAgentsState, (state) => state.loading);

export const getAgentsTotal = createSelector(getAgentsState, (state) => state.totalCount);
