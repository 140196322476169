import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as agentsActions from '../actions';
import { IAgentsState } from '../states/agents.state';
import { CardUserPhoto } from '@app/shell/team-card-photo/team-card-photo.component';
export const adapter = createEntityAdapter();

const agentsInitialState: IAgentsState = {
  loading: false,
  loaded: false,
  agents: [],
  totalCount: 0,
};

export const agentsReducer = createReducer(
  agentsInitialState,
  on(agentsActions.getAllAgents, agentsActions.getMoreAgents, (state) => ({ ...state, loading: true, loaded: false })),
  on(agentsActions.getAllAgentsSuccess, (state, { agents, totalCount }) => {
    return { ...state, loading: false, loaded: true, agents: transformDate(agents), totalCount };
  }),
  on(agentsActions.getMoreAgentsSuccess, (state, { agents, totalCount }) => {
    return { ...state, loading: false, loaded: true, agents: transformDate([...state.agents, ...agents]), totalCount };
  }),
  on(agentsActions.getAllAgentsFailure, agentsActions.getMoreAgentsFailure, (state) => ({ ...state, loading: false, loaded: false })),
);

function transformDate(agents: any): CardUserPhoto[] {
  return agents.map((agent: any, index: number) => ({
    ...agent,
    fullName: `${agent.name} ${agent.surname || ''}`,
    index: index + 1,
    achievement: agent.achievement?.nameRu || '',
  }));
}
