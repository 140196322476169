import { Injectable } from '@angular/core';
import { AuthService } from '@modules/auth/auth.service';
import {of, Subject} from 'rxjs';
import {catchError, exhaustMap, switchMap, tap} from 'rxjs/operators';
import { AcceptAgreementApiService } from './accept-agreement-api.service';
import * as models from './password-setup-api.model';
import { RegisterUserApiService } from './register-user-api.service';
import {GoogleAnalyticsService} from "@shared/services";

@Injectable({
  providedIn: 'root',
})
export class RegisterUserService {
  constructor(
    private registerUserApiService: RegisterUserApiService,
    private acceptAgreement: AcceptAgreementApiService,
    private authService: AuthService,
    private _googleAnalyticsService: GoogleAnalyticsService,
  ) {}

  #registerUserOperationInFlySubject: Subject<boolean> = new Subject<boolean>();
  public registerUserOperationInFly$ = this.#registerUserOperationInFlySubject.asObservable();

  #registerUserSubject: Subject<{ token: string; login: string; body: models.RegisterUserBody }> = new Subject<{ token: string; login: string; body: models.RegisterUserBody }>();
  public registerUser$ = this.#registerUserSubject.asObservable().pipe(
    tap(() => this.#registerUserOperationInFlySubject.next(true)),
    exhaustMap(({ token, login, body }) =>
      this.#registerUser(token, body).pipe(
        switchMap(() => this.acceptAgreement.accept(token, true)),
        switchMap(() => this.authService.login({ login, password: body.password })),
      ),
    ),
    tap(() => {
      this._googleAnalyticsService.event('send_sign_up', { status: 'success' });
      this.#registerUserOperationInFlySubject.next(false);
    }),
    catchError(() => {
      this._googleAnalyticsService.event('error_send_form', { field: 'send_sign_up' });
      return of(null);
    }),
  );

  public registerUser({ token, login, body }: { token: string; login: string; body: models.RegisterUserBody }): void {
    this.#registerUserSubject.next({ token, login, body });
  }

  #registerUser(token: string, body: models.RegisterUserBody) {
    return this.registerUserApiService.registerUser(token, body);
  }
}
