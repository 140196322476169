import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AgentGroupResponse,
  AllAgentGroupsResponse,
  AllAgentsData,
  AllAgentsResponse,
  SaveLeadFromMobileAppData,
  SaveLeadFromMobileAppResponse,
  TopAgentsByAverageRatingData,
  TopAgentsByAverageRatingResponse,
} from '@modules/our-specialists/models/our-specialists-model';
import { environment } from '@environments/environment';
import { catchError, exhaustMap, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable } from 'rxjs';
import { DeviceService } from '@helpers/device.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/ngrx/app-state/app-state';
import { getAgentsCollection, getAgentsLoading, getAgentsTotal } from '@app/ngrx/agents/states/agents-getters.state';
import { getAllAgents, getMoreAgents } from '@app/ngrx/agents/actions';
import { TopTeamFlags } from '@app/shell/top-team/top-team.component';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  agentsCollection$: Observable<any> = this.store.select(getAgentsCollection);
  agentsLoading$: Observable<boolean> = this.store.select(getAgentsLoading);
  agentsTotalCount$: Observable<number> = this.store.select(getAgentsTotal);
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private deviceService: DeviceService,
    private store: Store<AppState>,
  ) {}

  public getAllAgents(filter: TopAgentsByAverageRatingData) {
    return this.http.post<TopAgentsByAverageRatingResponse>(`${environment.apiUserManagerUrl}/open-api/agents/topAgentsByAverageRating`, filter).pipe(
      catchError((er) => {
        this.messageService.add({ severity: 'error', summary: er.message });
        return EMPTY;
      }),
    );
  }

  public sendRequestToSpecialist(params: SaveLeadFromMobileAppData) {
    return this.deviceService
      .getDeviceUUID()
      .pipe(exhaustMap((deviceUuid) => this.http.post<SaveLeadFromMobileAppResponse>(`${environment.apiDataManagerUrl}/open-api/leads/saveLeadFromMobileApp`, { ...params, deviceUuid })));
  }

  getAllAgentsStore(filter: AllAgentsData) {
    this.store.dispatch(getAllAgents({ filter }));
  }

  getMoreAgents(filter: AllAgentsData) {
    this.store.dispatch(getMoreAgents({ filter }));
  }

  public getAllAgentsByFilter(filter: AllAgentsData): Observable<AllAgentsResponse> {
    return this.http.post(`${environment.apiUserManagerUrl}/open-api/agents/findAgentsByFilter`, filter);
  }

  public getAgentGroups(filter: { pageNumber: number; pageSize: number; sortBy?: string; direction?: string; agentGroupFlagTypes?: TopTeamFlags[] }): Observable<AllAgentGroupsResponse> {
    return this.http.post(`${environment.apiViewManagerUrl}/open-api/agent-groups/getAllAgentGroups`, filter);
  }

  getAgentGroup(id: number): Observable<AgentGroupResponse> {
    return this.http.get(`${environment.apiViewManagerUrl}/open-api/agent-groups/getAgentGroupView/${id}`);
  }

  public getAgentGroupsFlags() {
    return this.http.get(`${environment.apiViewManagerUrl}/open-api/agent-groups/flags`);
  }
}
