import { createAction, props } from '@ngrx/store';
import * as models from '@modules/our-specialists/models/our-specialists-model';

const AGENTS = 'AGENTS';

export const getAllAgents = createAction(`[${AGENTS}] Get All Agents`, props<{ filter: models.AllAgentsData }>());
export const getAllAgentsSuccess = createAction(`[${AGENTS}] Get All Agents Success`, props<{ agents: any; totalCount: number }>());
export const getAllAgentsFailure = createAction(`[${AGENTS}] Get All Agents Failure`, props<{ errorMessage: string }>());

export const getMoreAgents = createAction(`[${AGENTS}] Get More Agents`, props<{ filter: models.AllAgentsData }>());
export const getMoreAgentsSuccess = createAction(`[${AGENTS}] Get More Agents Success`, props<{ agents: any; totalCount: number }>());
export const getMoreAgentsFailure = createAction(`[${AGENTS}] Get More Agents Failure`, props<{ errorMessage: string }>());
