import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NavigationRoutingModule } from '@app/navigation/navigation-routing.module';
import { PrincipalModule } from '@app/principal/principal.module';
import { PrincipalService } from '@app/principal/service/principal.service';
import { SettingsService } from '@app/settings.service';
import { environment } from '@environments/environment';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { JwtInterceptor } from '@helpers/jwt.interceptor';
import { UserService } from '@modules/user';
import { VideoPlayerModule } from '@modules/video-player/video-player.module';
import * as sharedModels from '@shared/models';
import { AddressService } from '@shared/services';
import * as consts from 'const';
import { IConfig as IConfigMask, NgxMaskModule } from 'ngx-mask';
import { exhaustMap, filter, tap } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { components } from './components';
import { MobileHeaderModule } from './navigation/components/mobile-header/mobile-header.module';
import { StoreModule } from '@ngrx/store';
import { effects, reducers } from '@app/ngrx/app-state/app-state';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ProgressInterceptor } from '@app/progress/progress.interceptor';
import { PreloaderComponent } from '@app/preloader/preloader.component';

export const options: Partial<IConfigMask> | (() => Partial<IConfigMask>) = {};
export function ifMobileDeviceRedirectToMobileSite() {
  let check = false;
  ((a) => {
    if (
      // eslint-disable-next-line max-len
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      // eslint-disable-next-line max-len
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  if (check) {
    // window.location.replace('https://dev.ivitrina.kz');
  }
  return () => check;
}

export function authenticateUser(principal: PrincipalService, userService: UserService) {
  if (!PrincipalService.accessToken) {
    return () => null as any;
  }
  return () =>
    userService
      .getUserProfile()
      .pipe(
        filter((user) => !!user),
        tap((user) => principal.setCurrentUser(user)),
      )
      .subscribe();
}

export function setCurrentCity(addressService: AddressService, settingsService: SettingsService) {
  return () =>
    addressService.getAddressObjectList(sharedModels.AddressObjectTypeList.City).pipe(
      tap((cityList) => settingsService.setCityList(cityList)),
      exhaustMap((cityList) =>
        SettingsService.city
          ? settingsService.setCurrentSelectedCityById(SettingsService.city.id, cityList)
          : settingsService.setCurrentSelectedCityById(environment.appDefaultSettings.cities.kazakhstan, cityList),
      ),
    );
}

export function redirectToRealPropertyDetails(router: Router) {
  if (location.pathname.includes('/object/')) {
    const chunks = location.href.split('/object/');
    const id = parseInt(chunks?.[1], 10);
    return () => (id ? router.navigate(['/kazakhstan', { outlets: { modal: 'realProperty' } }], { queryParams: { id } }).then() : {});
  } else {
    return () => {};
  }
}

@NgModule({
  declarations: [AppComponent, ...components],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(options),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    PrincipalModule,
    VideoPlayerModule,
    AppRoutingModule,
    MobileHeaderModule,
    NavigationRoutingModule,
    PreloaderComponent,
    HammerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ifMobileDeviceRedirectToMobileSite,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authenticateUser,
      deps: [PrincipalService, UserService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setCurrentCity,
      deps: [AddressService, SettingsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: redirectToRealPropertyDetails,
      deps: [Router],
      multi: true,
    },
    {
      provide: consts.KAZAKHSTAN_COUNTRY_TOKEN,
      useValue: environment.appDefaultSettings.countries.kazakhstan,
    },
    {
      provide: consts.CYPRUS_COUNTRY_TOKEN,
      useValue: environment.appDefaultSettings.countries.cyprus,
    },
    {
      provide: consts.VIEW_MANAGER_URI,
      useValue: environment.apiViewManagerUrl,
    },
    {
      provide: consts.DATA_MANAGER_URI,
      useValue: environment.apiDataManagerUrl,
    },
    {
      provide: consts.USER_MANAGER_URI,
      useValue: environment.apiUserManagerUrl,
    },
    {
      provide: consts.FILE_MANAGER_URI_TOKEN,
      useValue: environment.apiFileManagerUrl,
    },
    {
      provide: consts.OBJECT_TYPE_ID_TOKEN,
      useValue: consts.OBJECT_TYPE_ID,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressInterceptor,
      multi: true,
    },
    PrincipalService,
    SettingsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
