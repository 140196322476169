import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as agentsActions from '../actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AgentsService } from '@modules/our-specialists/services/agents.service';

@Injectable()
export class AgentsEffects {
  getAllAgents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(agentsActions.getAllAgents),
      switchMap(({ filter }) => {
        return this._agentsService.getAllAgentsByFilter(filter).pipe(
          map((data) => {
            return agentsActions.getAllAgentsSuccess({ agents: [...data.content.map(a => ({
              ...a,
              test: '11',
            }))], totalCount: data.totalElements });
          }),
          catchError((err) => of(agentsActions.getAllAgentsFailure({ errorMessage: err.message }))),
        );
      }),
    );
  });

  getMoreAgents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(agentsActions.getMoreAgents),
      switchMap(({ filter }) => {
        return this._agentsService.getAllAgentsByFilter(filter).pipe(
          map((data) => {
            return agentsActions.getMoreAgentsSuccess({ agents: data.content, totalCount: data.totalElements });
          }),
          catchError((err) => of(agentsActions.getMoreAgentsFailure({ errorMessage: err.message }))),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private _agentsService: AgentsService,
  ) {}
}
